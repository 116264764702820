import { PrismicLink, PrismicRichText } from '@prismicio/react';
import clsx from 'clsx';
import styled from 'styled-components';
import * as prismicH from '@prismicio/helpers';

import { HeroSlice } from '../../components/BaseSlice';
import { Bounded } from '../../components/Bounded';
import { Heading } from '../../components/Heading';

const Hero = ({ slice }) => {
  const darkText = !slice.primary.image.url || slice.primary.dark_text;
  const textCls = darkText
    ? 'text-shadow-light'
    : 'text-white text-shadow-dark';

  return (
    <div className="position-relative">
      <Cover
        style={{
          background: `url("${slice.primary.image.url}") no-repeat`
        }}
      />
      <Bounded as="section">
        <HeroSlice className="row">
          <div className="col-lg-8 col-md-12 pb-6">
            <PrismicRichText
              field={slice.primary.text}
              components={{
                heading1: ({ children }) => (
                  <Heading className={clsx('mb-3', textCls)}>
                    {children}
                  </Heading>
                ),
                paragraph: ({ children }) => (
                  <p className={clsx('mb-4 text-large fw-light', textCls)}>
                    {children}
                  </p>
                )
              }}
            />
            {prismicH.isFilled.link(slice.primary.primaryButtonLink) &&
              prismicH.isFilled.keyText(slice.primary.primaryButtonText) && (
                <PrismicLink
                  field={slice.primary.primaryButtonLink}
                  className="btn btn-primary px-4">
                  {slice.primary.primaryButtonText}
                </PrismicLink>
              )}{' '}
            {prismicH.isFilled.link(slice.primary.secondaryButtonLink) &&
              prismicH.isFilled.keyText(slice.primary.secondaryButtonText) && (
                <PrismicLink
                  field={slice.primary.secondaryButtonLink}
                  className="btn btn-light px-4">
                  {slice.primary.secondaryButtonText}
                </PrismicLink>
              )}
          </div>
        </HeroSlice>
      </Bounded>
    </div>
  );
};

export default Hero;

const Cover = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover !important;
  position: absolute;
  z-index: -1;
`;
